.mat-mdc-snack-bar-container {
  --gray4: hsl(0, 0%, 93%);
  --gray12: hsl(0, 0%, 9%);
  --normal-bg: #fff;
  --normal-border: var(--gray4);
  --normal-text: var(--gray12);
  --success-bg: hsl(143, 85%, 96%);
  --success-border: hsl(145, 92%, 87%);
  --success-text: hsl(140, 100%, 27%);
  --error-bg: hsl(359, 100%, 97%);
  --error-border: hsl(359, 100%, 94%);
  --error-text: hsl(360, 100%, 45%);
  --warning-bg: hsl(50, 100%, 95%);
  --warning-border: hsl(50, 100%, 85%);
  --warning-text: hsl(35, 100%, 35%);

  &.success {
    .mdc-snackbar__surface {
      background: var(--success-bg) !important;
      border-color: var(--success-border) !important;
      color: var(--success-text) !important;

      .mat-mdc-snack-bar-label {
        color: var(--success-text) !important;
      }

      .mdc-button {
        color: var(--success-text) !important;
        background: var(--success-bg) !important;
        border-color: var(--success-border) !important;
      }
    }
  }

  &.warning {
    .mdc-snackbar__surface {
      background: var(--warning-bg) !important;
      border-color: var(--warning-border) !important;
      color: var(--warning-text) !important;

      .mat-mdc-snack-bar-label {
        color: var(--warning-text) !important;
      }

      .mdc-button {
        color: var(--warning-text) !important;
        background: var(--warning-bg) !important;
        border-color: var(--warning-border) !important;
      }
    }
  }

  &.error {
    .mdc-snackbar__surface {
      background: var(--error-bg) !important;
      border-color: var(--error-border) !important;
      color: var(--error-text) !important;

      .mat-mdc-snack-bar-label {
        color: var(--error-text) !important;
      }

      .mdc-button {
        color: var(--error-text) !important;
        background: var(--error-bg) !important;
        border-color: var(--error-border) !important;
      }
    }
  }

  .mdc-snackbar__surface {
    position: relative !important;
    background: var(--normal-bg) !important;
    border: 1px solid var(--normal-border) !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 
                0 1px 2px rgba(0, 0, 0, 0.2) !important;
    padding: 16px !important;
    min-height: 40px !important;
    display: flex !important;
    align-items: center !important;
    gap: 6px !important;
    transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1) !important;
    transform-origin: top !important;
    
    .mat-mdc-snack-bar-label {
      font-size: 13px !important;
      font-weight: 500 !important;
      line-height: 1.5 !important;
      color: var(--normal-text) !important;
      padding: 0 !important;
      margin: 0 !important;
      padding-right: 20px !important;
    }

    .mat-mdc-snack-bar-actions {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      
      .mdc-button {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        min-width: 20px !important;
        width: 20px !important;
        height: 20px !important;
        padding: 0 !important;
        border-radius: 50% !important;
        font-size: 18px !important;
        line-height: 1 !important;
        color: var(--normal-text) !important;
        background: var(--normal-bg) !important;
        border: 1px solid var(--normal-border) !important;
        transform: translate(35%, -35%) !important;
        transition: all 200ms ease !important;
        
        &::before {
          content: '✕' !important;
          font-size: 12px !important;
          font-weight: 500 !important;
        }
        
        &:hover {
          background: var(--gray4) !important;
        }

        .mat-mdc-button-touch-target {
          display: none !important;
        }

        .mdc-button__label {
          display: none !important;
        }
      }
    }
  }
} 