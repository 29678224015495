@use '../variables';

.new-item-modal {
  //width: 80%;
  //min-height: 400px;
  width: 800px;
  max-height: 95vh;
  padding: variables.$modal-padding;
  color: white;

  .mat-h3 {
    color: var(--light-primary-text);
  }

  .mat-description {
    color: var(--light-primary-text);
  }
}

.modal {
  color: var(--light-primary-text);
}

.bottom-sheet-actions {
  padding: 24px;
  margin: 0;
  border-top: 1px solid var(--secondary);
  background-color: var(--light-background);
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  
  button {
    min-width: 80px;
    height: 40px;
    border-radius: 8px;
    transition: all 0.15s;

    &[color="accent"] {
      background-color: var(--accent);

      &:hover {
        background-color: var(--accent-darker);
      }
    }

    &[mat-stroked-button] {
      border-color: var(--secondary);
      color: var(--dark-secondary-text);

      &:hover {
        background-color: var(--secondary-lighter);
      }
    }
  }
}

// Global dialog content styles
.mat-mdc-dialog-content {
  padding: 0 !important;
  height: inherit;
  max-height: inherit;
}

.mat-mdc-dialog-title {
  color: var(--dark-primary-text);
  font-size: 1.25rem;
  font-weight: 600;
  padding: 24px;
  margin: 0;
  border-bottom: 1px solid var(--secondary);
  display: flex;
  align-items: center;
}